import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import AktivaAset from "./AktivaAset";
import KekayaanBersih from "./KekayaanBersih";
import LampiranDokumenPendukung from "./LampiranDokumenPendukung";
import Pasiva from "./Pasiva";
import PendapatanDanBeban from "./PendapatanDanBeban";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const InstitutionalFinancialReport = ({ tools, methods, isProfit }) => {
  const navigate = useNavigate();

  const data = tools[0]?.financial_report;

  const {
    watch,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useFormContext();

  const [isEdit, setIsEdit] = useState(null);
  const [isEditVerification, setIsEditVerification] = useState(null);

  const toogleIsEdit = (index) => {
    if (isEdit === index) {
      return setIsEdit(null);
    }

    setIsEdit(index);
  };
  const toogleIsEditVerification = (index) => {
    if (isEditVerification === index) {
      return setIsEditVerification(null);
    }

    setIsEditVerification(index);
  }

  const sumAssetTotal = (index) => {
    let total = 0;

    const current_asset =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.total`)
      ) || 0;
    const fixed_asset =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.fixed_asset.total`)
      ) || 0;
    const other_asset =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.other_asset_amount`)
      ) || 0;

    total = current_asset + fixed_asset + other_asset;

    methods.setValue(
      `tools.0.financial_report.${index}.total_asset`,
      convertMoney(total)
    );
  };

  const sumTotalDebt = (index) => {
    let total = 0;

    const short_term_debt =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.total`)
      ) || 0;
    const long_term_debt =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.long_term_debt.total`)
      ) || 0;

    total = short_term_debt + long_term_debt;

    methods.setValue(
      `tools.0.financial_report.${index}.total_debt`,
      convertMoney(total)
    );
  };

  const sumCurrentAssetTotal = (index) => {
    let total = 0;
    let otherAssetSum = 0;
    const cash =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.cash`)
      ) || 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.equipment`)
      ) || 0;
    const supply =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.supply`)
      ) || 0;
    const receivables =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.debts`)
      ) || 0;

    const downPayment =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.current_asset.advance_payment_program`)
      ) || 0;

    const otherAsset =
      watch(`tools.0.financial_report.${index}.current_asset.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }

    total =
      cash + equipment + supply + receivables + otherAssetSum + downPayment;

    setValue(
      `tools.0.financial_report.${index}.current_asset.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumFixedAssetTotal = (index) => {
    let total = 0;
    let otherAssetSum = 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.fixed_asset.equipment`)
      ) || 0;

    const wip =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.fixed_asset.wip`)
      ) || 0;
    const land_and_buildings =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.fixed_asset.land_and_buildings`)
      ) || 0;
    const otherAsset =
      watch(`tools.0.financial_report.${index}.fixed_asset.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }
    total = wip + equipment + land_and_buildings + otherAssetSum;

    setValue(
      `tools.0.financial_report.${index}.fixed_asset.total`,
      convertMoney(total)
    );

    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumOtherAssetTotal = (index) => {
    let otherAssetSum = 0;
    const otherAsset = watch(`tools.0.financial_report.${index}.other_asset`) || [];
    if (otherAsset.length > 0) {
      otherAssetSum = otherAsset.reduce(
        (acc, p) => acc + convertFormattedValueToNumber(p?.value) || 0,
        0
      );
    }
    setValue(
      `tools.0.financial_report.${index}.other_asset_amount`,
      convertMoney(otherAssetSum)
    );
    sumTotalNetWorth(index);
    sumAssetTotal(index);
  };

  const sumShortTermTotal = (index) => {
    let total = 0;
    const cash =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.cash`)
      ) || 0;
    const equipment =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.equipment`)
      ) || 0;
    const other_debt =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.other_debt`)
      ) || 0;
    const supply =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.supply`)
      ) || 0;
    const receivables =
      convertFormattedValueToNumber(
        watch(`tools.0.financial_report.${index}.short_term_debt.receivables`)
      ) || 0;
    total = cash + equipment + other_debt + supply + receivables;
    setValue(
      `tools.0.financial_report.${index}.short_term_debt.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);
    sumTotalDebt(index);
  };

  const sumLongTermTotal = (index) => {
    let total = 0;
    const long_term_debt = convertFormattedValueToNumber(
      watch(`tools.0.financial_report.${index}.long_term_debt.debt`) || 0
    );
    total = long_term_debt;
    setValue(
      `tools.0.financial_report.${index}.long_term_debt.total`,
      convertMoney(total)
    );
    sumTotalNetWorth(index);

    sumTotalDebt(index);
  };

  const sumTotalNetWorth = (index) => {
    let totalNetWorth = 0;
    const current_asset_total =
      convertFormattedValueToNumber(
        getValues(`tools.0.financial_report.${index}.current_asset.total`)
      ) || 0;
    const fixed_asset_total =
      convertFormattedValueToNumber(
        getValues(`tools.0.financial_report.${index}.fixed_asset.total`)
      ) || 0;
    const other_asset_amount =
      convertFormattedValueToNumber(
        getValues(`tools.0.financial_report.${index}.other_asset_amount`)
      ) || 0;
    const short_term_debt_total =
      convertFormattedValueToNumber(
        getValues(`tools.0.financial_report.${index}.short_term_debt.total`)
      ) || 0;
    const long_term_debt_total =
      convertFormattedValueToNumber(
        getValues(`tools.0.financial_report.${index}.long_term_debt.total`)
      ) || 0;
    totalNetWorth =
      current_asset_total +
      fixed_asset_total +
      other_asset_amount -
      (short_term_debt_total + long_term_debt_total);

    setValue(
      `tools.0.financial_report.${index}.total_net_worth`,
      convertMoney(totalNetWorth)
    );
  };

  const sumTotalNetIncome = (index) => {
    let totalNetIncome = 0;
    const draftLemtara = {};
    if (draftLemtara?.label?.includes('Non')) {
      const total_income =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.total_income`)
        ) || 0;
      const total_incurred =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.total_incurred`)
        ) || 0;
      const program_costs =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.program_costs`)
        ) || 0;
      const non_program_costs =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.non_program_costs`)
        ) || 0;

      setValue(
        `tools.0.financial_report.${index}.total_program_costs`,
        convertFormattedValueToNumber(program_costs + non_program_costs)
      );

      const total_program_costs =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.total_program_costs`)
        ) || 0;

      totalNetIncome =
        total_income + total_incurred - (program_costs + non_program_costs);
    } else {
      const total_income =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.total_income`)
        ) || 0;
      const total_incurred =
        convertFormattedValueToNumber(
          watch(`tools.0.financial_report.${index}.total_incurred`)
        ) || 0;
      totalNetIncome = total_income - total_incurred;
    }

    setValue(
      `tools.0.financial_report.${index}.total_net_income`,
      convertMoney(totalNetIncome)
    );
  };

  function convertFormattedValueToNumber(formattedValue) {
    if (
      formattedValue === '' ||
      !formattedValue ||
      typeof formattedValue !== 'string'
    )
      return null;
    const numericString = formattedValue?.replace(/[^0-9]/g, '');

    const numberValue = parseInt(numericString, 10);

    return numberValue;
  }

  const convertMoney = (value) => {
    let oriValue = String(value);
    oriValue = oriValue.includes('.') ? oriValue.split('.').join('') : oriValue;
    let modifValue = '';
    let count = 1;
    if (oriValue !== '' && oriValue.length > 3) {
      const splitValue = oriValue.split('');
      for (let i = splitValue.length - 1; i >= 0; i--) {
        if (count % 3 === 0 && count !== 0 && i !== 0) {
          modifValue = `.${splitValue[i]}${modifValue}`;
        } else {
          modifValue = `${splitValue[i]}${modifValue}`;
        }
        count += 1;
      }
    } else {
      modifValue = oriValue;
    }
    return modifValue;
  };

  return (
    <div>
      {!isProfit && (
        <Card className="mb-4">
          <CardHeader>
            <div className="flex justify-center items-center">
              <div className="flex-1">Informasi Rekening</div>
              <div className="flex-1 text-right">
                {isEdit === "info" ? (
                  <Button
                    type="button"
                    className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                    onClick={() => toogleIsEdit("info")}
                  >
                    <BiPencil />
                    Simpan Perubahan
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                    onClick={() => toogleIsEdit("info")}
                  >
                    <BiPencil />
                    Ubah
                  </Button>
                )}
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div className="flex flex-col">
              <div className="flex-1 pt-2">
                <InputForm
                  showPrefix={true}
                  prefix={"Rp. "}
                  controllerName={`advance_payment_program`}
                  className={`py-2 px-4 mt-2 border w-full rounded-md`}
                  label={"Uang Muka Program"}
                  placeholder={"0"}
                  required={true}
                  disabled={isEdit !== "info"}
                />
              </div>
            </div>
          </CardBody>
        </Card>
      )}
      <div className="flex flex-col gap-4">
        {data?.map((field, index) => {
          return (
            <Card>
              <CardHeader>
                <div className="flex justify-center items-center">
                  <div className="flex-1">
                    Laporan Keuangan Lembaga - Tahun {field.year}{" "}
                  </div>
                  <div className="flex-1 text-right">
                    {isEdit === index ? (
                      <Button
                        type="button"
                        className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                        onClick={() => toogleIsEdit(index)}
                      >
                        <BiPencil />
                        Simpan Perubahan
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                        onClick={() => toogleIsEdit(index)}
                      >
                        <BiPencil />
                        Ubah
                      </Button>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="flex flex-col">
                  <AktivaAset
                    data={data[index]}
                    index={index}
                    methods={methods}
                    isEdit={isEdit === index}
                    sumCurrentAssetTotal={sumCurrentAssetTotal}
                    sumFixedAssetTotal={sumFixedAssetTotal}
                  />
                  <Pasiva
                    data={data[index]}
                    index={index}
                    methods={methods}
                    isEdit={isEdit === index}
                    sumShortTermTotal={sumShortTermTotal}
                    sumLongTermTotal={sumLongTermTotal}
                  />
                  <KekayaanBersih
                    data={data[index]}
                    index={index}
                    isEdit={isEdit === index}
                  />
                  <PendapatanDanBeban
                    data={data[index]}
                    index={index}
                    isProfit={isProfit}
                    isEdit={isEdit === index}
                    sumTotalNetIncome={sumTotalNetIncome}
                  />
                  {/* <LampiranDokumenPendukung data={data[index]} index={index} /> */}
                  {/* button Verification */}
                  <RadioVerificationBadge
                    className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
                    name={`tools.0.financial_report.${index}.result_verification`}
                    nameNotes={`tools.0.financial_report.${index}.reason`}
                    isEditVerification={isEditVerification === index}
                    toogleIsEditVerification={() => toogleIsEditVerification(index)}
                    errors={
                      errors?.tools
                        ? errors?.tools[0]?.financial_report[index]
                            ?.result_verification
                        : false
                    }
                  />
                </div>
              </CardBody>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default InstitutionalFinancialReport;
