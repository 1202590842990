import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const PendapatanDanBeban = ({ data, index, isProfit, isEdit, sumTotalNetIncome }) => {
  return (
    <div className="flex flex-col">
      <div className="mt-[20px]">
        {isProfit ? (
          <CardForm label={`Pendapatan dan Beban`} buttonCollapse={true}>
            <div className="space-y-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.total_net_income`}
                className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Pendapatan Bersih (Pendapatan - Beban)"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled
                formatNumber
              />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <InputForm
                  controllerName={`tools.0.financial_report.${index}.total_income`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Pendapatan"}
                  desc={"Seluruh Pendapatan atau Penerimaan yang Diperoleh"}
                  placeholder={"0"}
                  prefix={"Rp."}
                  disabled={!isEdit}
                  formatNumber
                  onChangeInput={(e) => {
                    sumTotalNetIncome(index);
                  }}
                />
                <InputForm
                  controllerName={`tools.0.financial_report.${index}.total_incurred`}
                  className={`py-2 px-4border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Beban"}
                  desc={
                    "Seluruh Beban atau Biaya yang Dikeluarkan untuk Menghasilkan Pendapatan"
                  }
                  placeholder={"0"}
                  prefix={"Rp."}
                  disabled={!isEdit}
                  formatNumber
                  onChangeInput={(e) => {
                    sumTotalNetIncome(index);
                  }}
                />
              </div>
            </div>
          </CardForm>
        ) : (
          <>
            {" "}
            <CardForm label={`Pendapatan`} buttonCollapse={true}>
              <div className="space-y-4">
                <InputForm
                  controllerName={`tools.0.financial_report.${index}.total_net_income`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Total Pendapatan"}
                  placeholder={"0"}
                  prefix={"Rp."}
                  disabled={!isEdit}
                  formatNumber
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputForm
                    controllerName={`tools.0.financial_report.${index}.total_income`}
                    className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Kontribusi"}
                    desc={
                      "Pendapatan yang Berasal dari Sumbangan, Donasi, dan Sejenisnya"
                    }
                    placeholder={"0"}
                    prefix={"Rp."}
                    disabled={!isEdit}
                    formatNumber
                  />
                  <InputForm
                    controllerName={`tools.0.financial_report.${index}.total_incurred`}
                    className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Pendapatan Lainnya"}
                    desc={
                      "Pendapatan yang Berasal Atas Jasa yang Diberikan dan Investasi yang Dihasilkan"
                    }
                    placeholder={"0"}
                    prefix={"Rp."}
                    disabled={!isEdit}
                    formatNumber
                  />
                </div>
              </div>
            </CardForm>
            <CardForm label={`Beban`} buttonCollapse={true}>
              <div className="space-y-4">
                <InputForm
                  controllerName={`tools.0.financial_report.${index}.total_program_costs`}
                  className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                  label={"Total Biaya"}
                  placeholder={"0"}
                  prefix={"Rp."}
                  disabled={!isEdit}
                  formatNumber
                />
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputForm
                    controllerName={`tools.0.financial_report.${index}.program_cost`}
                    className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Biaya Program"}
                    desc={
                      "Biaya atau Beban yang Dikeluarkan untuk Melakukan Kegiatan/Program"
                    }
                    placeholder={"0"}
                    prefix={"Rp."}
                    disabled={!isEdit}
                    formatNumber
                  />
                  <InputForm
                    controllerName={`tools.0.financial_report.${index}.non_program_cost`}
                    className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                    label={"Biaya Non Program"}
                    desc={
                      "Biaya atau Beban yang Dikeluarkan untuk Memperoleh Pendapatan"
                    }
                    placeholder={"0"}
                    prefix={"Rp."}
                    disabled={!isEdit}
                    formatNumber
                  />
                </div>
              </div>
            </CardForm>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default PendapatanDanBeban;
