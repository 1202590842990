/* eslint-disable react-hooks/exhaustive-deps */
import { RiFile2Line } from "react-icons/ri";

import {
  Card,
  CardHeader,
  CardBody,
  TitleText,
  InputForm,
  InputFormUpload,
  Button,
} from "components";
import { BiPencil, BiSolidSave } from "react-icons/bi";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { Description } from "@storybook/blocks";
import { useSelector } from "react-redux";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
const { useNavigate } = require("react-router");

const DokumenPendukungChild = ({
  data,
  methods,
  obj,
  isEdit = false,
  toogleIsEdit = () => {},
}) => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState([]);
  const sortedData = data?.sort((a, b) => a?.key.localeCompare(b?.key));

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const draftDataOnRedux = useSelector(
    (state) => state?.draftFinancialProfile?.data || {}
  );
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    setFileData([]);

    for (let key in draftDataOnRedux?.step_1) {
      methods.setValue(key, draftDataOnRedux?.step_1[key]);
    }

    for (let field in obj) {
      if (field?.includes("reason") && field?.includes("result_verification")) {
        methods.setValue(field, obj[field]);
      }
    }
    data?.map((field) => {
      if (field.key.includes("description")) {
        methods.setValue(field.key, field.data);
      }
    });
  }, []);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center ">
          <div>Verifikasi Dokumen Pendukung</div>
          {isEdit ? (
            <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiSolidSave />
              Simpan Perubahan
            </Button>
          ) : (
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
              onClick={toogleIsEdit}
            >
              <BiPencil />
              Ubah
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-[24px] mb-8">
          {sortedData?.map((field, i) => {
            if (
              !field.key.includes("description") &&
              !field.key.includes("Sop") &&
              !field.key.includes("Contract") &&
              !field.key.includes("Completion")
            ) {
              return (
                <>
                  <div className="flex flex-col gap-[24px] w-full">
                    <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                      <div className="flex gap-4">
                        <div>
                          <div className="bg-[#EEFFF4] rounded-full p-4">
                            <RiFile2Line className="text-primary-600 text-[28px]" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="font-medium">{field.key}</span>
                          <span>{field.size} MB</span>
                          <span
                            onClick={() => handleClick(field.url)}
                            style={{
                              cursor: "pointer",
                              color: "#00BD52",
                            }}
                            className="font-semibold text-primary-600"
                          >
                            Lihat Dokumen
                          </span>
                        </div>
                      </div>
                      {isEdit && (
                        <InputFormUpload
                          controllerName={"files_" + field.propsName}
                          maxSize={102400}
                          // label="Upload Dokumen Pendukung"
                          // isMulti
                          hideSectionUpload={!isEdit}
                          listFiles={watch(`${field.propsName}`) ?? []}
                        />
                      )}
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        <div className="flex justify-center items-center gap-[24px] mb-8">
          <div className="flex flex-col gap-[24px] w-full">
            {sortedData?.map((field, i) => {
              if (!field?.key.includes("description")) {
                if (
                  field?.key === "Sop Document" ||
                  field?.key === "Contract Document" ||
                  field?.key === "Completion Document"
                ) {
                  return (
                    <>
                      <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl">
                        <div className="flex gap-4">
                          <div>
                            <div className="bg-[#EEFFF4] rounded-full p-4">
                              <RiFile2Line className="text-primary-600 text-[28px]" />
                            </div>
                          </div>
                          <div className="flex flex-col gap-2">
                            <span className="font-medium"> {field.key}</span>
                            <span>{field.size} MB</span>
                            <span
                              onClick={() => handleClick(field.url)}
                              style={{
                                cursor: "pointer",
                                color: "#00BD52",
                              }}
                              className="font-semibold text-primary-600"
                            >
                              Lihat Dokumen
                            </span>
                          </div>
                        </div>
                        {isEdit && (
                          <InputFormUpload
                            controllerName={"files_" + field.propsName}
                            maxSize={102400}
                            // label="Upload Dokumen Pendukung"
                            // isMulti
                            hideSectionUpload={!isEdit}
                            listFiles={watch(`${field.propsName}`) ?? []}
                          />
                        )}
                      </div>
                    </>
                  );
                }
              }
            })}
          </div>
          <div className="flex flex-col gap-[24px] w-full">
            <div className="space-y-2">
              <InputForm
                controllerName={"completion_description"}
                className={`py-2 border w-full rounded-md flex-1`}
                label={"Uraian Singkat Terkait Isi dokumen"}
                placeholder={"Uraian Singkat Terkait Isi dokumen"}
                textArea
                disabled={!isEdit}
                // readOnly
              />
            </div>
            <div className="space-y-2">
              <InputForm
                controllerName={"contract_description"}
                className={`py-2 border w-full rounded-md flex-1`}
                label={"Uraian Singkat Terkait Isi dokumen"}
                placeholder={"Uraian Singkat Terkait Isi dokumen"}
                textArea
                disabled={!isEdit}
                // readOnly
              />
            </div>
            <div className="space-y-2">
              <InputForm
                controllerName={"sop_description"}
                className={`py-2 border w-full rounded-md flex-1`}
                label={"Uraian Singkat Terkait Isi dokumen"}
                placeholder={"Uraian Singkat Terkait Isi dokumen"}
                textArea
                disabled={!isEdit}
                // readOnly
              />
            </div>
          </div>
        </div>

        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"result_verification"}
          nameNotes={"reason"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={
            errors?.result_verification ? errors?.result_verification : false
          }
        />
      </CardBody>
    </Card>
  );
};

export default DokumenPendukungChild;
