import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import SupportingDocumentChild from "./Form/DokumenPendukung";
import { BiCheck } from "react-icons/bi";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import { useProgramExperienceContext } from "../ProgramExperienceState";

const FieldLabel = {
  general: {
    TAB2_VERIFICATION: "Hasil Verifikasi Tab Peralatan/ Perlengkapan",
    WRONG_DATA_DESCRIPTION: "Deskripsi Ketidaksesuaian Data",
    ARRAY_TOOLS_VALIDATION: {
      NAMA: "Nama",
      JUMLAH: "Jumlah",
      MERK_TIPE: "Merk & Tipe",
      KONDISI: "Kondisi",
      KATEGORI: "Kategori",
      KAPASITAS_SAAT_INI: "Kapasitas Saat Ini",
      TAHUN_PEMBUATAN: "Tahun Pembuatan",
      LOKASI_ALAT: "Lokasi Alat",
    },
  },
  info: {},
};

const PeralatanPerlengkapan = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  setFormState,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);
  const { ProgramExperienceState, setProgramExperienceState } =
    useProgramExperienceContext();
  const validationSchema = useMemo(() =>
    Yup.object().shape({
      tab2Verification: Yup.boolean()
        .required()
        .label(FieldLabel.general.TAB2_VERIFICATION),
      wrongDataDescription: Yup.string().label(
        FieldLabel.general.WRONG_DATA_DESCRIPTION
      ),
      experience: Yup.array().of(
        Yup.object().shape({
          nama: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.NAMA),
          jumlah: Yup.mixed()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.JUMLAH),
          merk_tipe: Yup.mixed()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.MERK_TIPE),
          kondisi: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.KONDISI),
          kategori: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.KATEGORI),
          kapasitas_saat_ini: Yup.string()
            .required()
            .label(
              FieldLabel.general.ARRAY_TOOLS_VALIDATION.KAPASITAS_SAAT_INI
            ),
          tahun_pembuatan: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.TAHUN_PEMBUATAN),
          lokasi_alat: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.LOKASI_ALAT),
        })
      ),
      status_approval_document: Yup.string().required(
        `status approval document is required`
      ),
      notes_approval_document: Yup.string().nullable(true),
    })
  );

  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      tab2Verification: false,
      experience: [
        {
          nama: `Lorem ipsum dolor sit amet.`,
          jumlah: `Lorem ipsum dolor sit amet.`,
          merk_tipe: `Lorem ipsum dolor sit amet.`,
          kondisi: `Lorem ipsum dolor sit amet.`,
          kategori: `Lorem ipsum dolor sit amet.`,
          kapasitas_saat_ini: `Lorem ipsum dolor sit amet.`,
          tahun_pembuatan: `Lorem ipsum dolor sit amet.`,
          lokasi_alat: `Lorem ipsum dolor sit amet.`,
        },
        {
          nama: `Lorem ipsum dolor sit amet.`,
          jumlah: `Lorem ipsum dolor sit amet.`,
          merk_tipe: `Lorem ipsum dolor sit amet.`,
          kondisi: `Lorem ipsum dolor sit amet.`,
          kategori: `Lorem ipsum dolor sit amet.`,
          kapasitas_saat_ini: `Lorem ipsum dolor sit amet.`,
          tahun_pembuatan: `Lorem ipsum dolor sit amet.`,
          lokasi_alat: `Lorem ipsum dolor sit amet.`,
        },
      ],
    },
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsexperience,
    appends: appendsexperience,
    remove: removeexperience,
  } = useFieldArray({
    control,
    name: "experience",
  });

  const navigate = useNavigate();

  const getFileMutation = useMutation(async ({ id, key, notes }) => {
    try {
      if (!Array.isArray(id)) {
        const response = await getFile(id);
        let temp = {
          key: key,
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: Math.round(parseInt(response.data.data.size) / 1000),
          documentName: transformKey(key),
          notes
        };
        setDocumentData((prevState) => [...prevState, temp]);
      }
    } catch (error) {
      throw error;
    }
  });

  function transformKey(key) {
    const transformedString = key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return transformedString;
  }

  useEffect(() => {
    setDocumentData([]);
    if (data) {
      const docs = data?.experience_programme_document;
      const fetchDocs = [
        'audit_result_document',
        'contract_document',
        'program_report_document',
      ];

      fetchDocs?.forEach((field, index) => {
        if (Array.isArray(docs[field])) {
          docs[field].forEach((id, idx) => {
            getFileMutation.mutate({
              id,
              index,
              key: field,
              notes: docs?.['desc_' + field]?.[idx],
            });
          });
        }
      });
    }
    setIsFetching(false);
    trigger();
  }, [data]);

  useEffect(() => {
    let temp = methods.getValues();
    setProgramExperienceState((prevState) => ({
      ...prevState,
      supporting_documents: documentData,
    }));
  }, [documentData]);

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {!isFetching && (
          <SupportingDocumentChild
            experience={documentData}
            methods={methods}
            data={data}
            isEdit={isEdit}
            toogleIsEdit={toogleIsEdit}
          />
        )}

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                const formData = methods.getValues();
                setProgramExperienceState((prevState) => ({
                  ...prevState,
                  supporting_documents: formData,
                }));
                setFormState(formData);
                if (isValid) setActiveTab(activeTab + 1);
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default PeralatanPerlengkapan;
