import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { convertMoney, deconvertMoney } from "helpers";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const Pasiva = ({
  data,
  index,
  methods,
  isEdit,
  sumShortTermTotal,
  sumLongTermTotal
}) => {
  return (
    <div className="flex flex-col">
      <div className="mt-[20px]">
        <CardForm label={`Pasiva/ Kewajiban/ Utang`} buttonCollapse={true}>
          <div className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.total_debt`}
                className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Total Pasiva/Kewajiban/Utang"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={true}
                formatNumber
              />
            </div>
            <div className="my-2">
              <span className="text-[#01A24A] font-[600] ">
                D. Utang Jangka Pendek{" "}
                <span className="font-[400]">(Kurang dari 1 Tahun)</span>
              </span>
            </div>
            <InputForm
              controllerName={`tools.0.financial_report.${index}.short_term_debt.total`}
              className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
              label={"Jumlah (D)"}
              placeholder={"0"}
              prefix={"Rp."}
              disabled
              formatNumber
            />
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <InputForm
                controllerName={`tools.0.financial_report.${index}.short_term_debt.cash`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Utang Dagang"}
                desc={"Utang yang berkaitan dengan kegiatan usaha"}
                placeholder={"0"}
                prefix={"Rp."}
                disabled={!isEdit}
                formatNumber
                onChangeInput={(e) => {
                  sumShortTermTotal(index);
                }}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.short_term_debt.equipment`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Utang Pajak"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={"Utang yang berkaitan dengan pajak"}
                disabled={!isEdit}
                formatNumber
                onChangeInput={(e) => {
                  sumShortTermTotal(index);
                }}
              />
              <InputForm
                controllerName={`tools.0.financial_report.${index}.short_term_debt.other_debt`}
                className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
                label={"Utang Lainnya"}
                placeholder={"0"}
                prefix={"Rp."}
                desc={
                  "Utang yang berkaitan selain dengan kegiatan usaha dan pajak"
                }
                disabled={!isEdit}
                formatNumber
                onChangeInput={(e) => {
                  sumShortTermTotal(index);
                }}
              />
            </div>
          </div>

          <div className="space-y-4">
            <span className="text-[#01A24A] font-[600] ">
              E. Utang Jangka Panjang{" "}
              <span className="font-[400]">(Lebih dari 1 Tahun)</span>
            </span>
            <InputForm
              controllerName={`tools.0.financial_report.${index}.long_term_debt.total`}
              className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
              label={"Jumlah (E)"}
              placeholder={"0"}
              prefix={"Rp."}
              disabled
              formatNumber
            />
            <InputForm
              controllerName={`tools.0.financial_report.${index}.long_term_debt.debt`}
              className={`py-2 px-4  border w-full rounded-md flex-1 border-[#D0D5DD]`}
              label={"Utang Jangka Panjang"}
              placeholder={"0"}
              prefix={"Rp."}
              disabled={!isEdit}
              formatNumber
              onChangeInput={(e) => {
                sumLongTermTotal(index);
              }}
            />
          </div>
        </CardForm>
      </div>
    </div>
  );
};

export default Pasiva;
