import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const KekayaanBersih = ({ data, index, isEdit }) => {
  const navigate = useNavigate();

  const { watch, getValues } = useFormContext();

  return (
    <div className="flex flex-col">
      <div className="mt-[20px]">
        <CardForm label={`Kekayaan Bersih`} buttonCollapse={true}>
          <div className="space-y-4">
            <InputForm
              controllerName={`tools.0.financial_report.${index}.total_net_worth`}
              className={`py-2 px-4 border w-full rounded-md flex-1 border-[#D0D5DD]`}
              label={"Jumlah (aktiva/Aset) - (Pasiva/ kewajiban/ utang)"}
              placeholder={"0"}
              prefix={"Rp."}
              disabled
              formatNumber
            />
          </div>
        </CardForm>
      </div>
    </div>
  );
};

export default KekayaanBersih;
