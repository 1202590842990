import { useNavigate } from "react-router";
import PengalamanPengelolaanProgramChild from "./Form/PengalamanPengelolaanProgram";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useMutation } from "@tanstack/react-query";
import {
  getFinancialProfile,
  getProgramExperience,
} from "services/danaProgram/danaProgramService";
import { BiCheck } from "react-icons/bi";
import { useProgramExperienceContext } from "../ProgramExperienceState";
import moment from "moment";

const FieldLabel = {
  general: {
    TAB2_VERIFICATION: "Hasil Verifikasi Tab Peralatan/ Perlengkapan",
    WRONG_DATA_DESCRIPTION: "Deskripsi Ketidaksesuaian Data",
    ARRAY_TOOLS_VALIDATION: {
      NAMA: "Nama",
      JUMLAH: "Jumlah",
      MERK_TIPE: "Merk & Tipe",
      KONDISI: "Kondisi",
      KATEGORI: "Kategori",
      KAPASITAS_SAAT_INI: "Kapasitas Saat Ini",
      TAHUN_PEMBUATAN: "Tahun Pembuatan",
      LOKASI_ALAT: "Lokasi Alat",
    },
  },
  info: {},
};

const PengalamanPengelolaanProgram = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  submitData,
}) => {
  const { ProgramExperienceState, setProgramExperienceState } =
    useProgramExperienceContext();
  const validationSchema = useMemo(() =>
    Yup.object().shape({
      tab2Verification: Yup.boolean()
        .required()
        .label(FieldLabel.general.TAB2_VERIFICATION),
      wrongDataDescription: Yup.string().label(
        FieldLabel.general.WRONG_DATA_DESCRIPTION
      ),
      experience: Yup.array().of(
        Yup.object().shape({
          nama: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.NAMA),
          jumlah: Yup.mixed()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.JUMLAH),
          merk_tipe: Yup.mixed()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.MERK_TIPE),
          kondisi: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.KONDISI),
          kategori: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.KATEGORI),
          kapasitas_saat_ini: Yup.string()
            .required()
            .label(
              FieldLabel.general.ARRAY_TOOLS_VALIDATION.KAPASITAS_SAAT_INI
            ),
          tahun_pembuatan: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.TAHUN_PEMBUATAN),
          lokasi_alat: Yup.string()
            .required()
            .label(FieldLabel.general.ARRAY_TOOLS_VALIDATION.LOKASI_ALAT),
        })
      ),
      status_approval_programme: Yup.string().required(
        `status approval programme is required`
      ),
      notes_approval_programme: Yup.string().nullable(true),
    })
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      tab2Verification: false,
      experience: [{}],
    },
  });

  const { control, trigger } = { ...methods };

  const {
    fields: fieldsExperience,
    append: appendsExperience,
    remove: removeExperience,
  } = useFieldArray({
    control,
    name: "experience",
  });

  useEffect(() => {
    fieldsExperience?.map((index) => removeExperience(index));
    appendsExperience(data?.programme?.map((item) => ({
      ...item,
      year: Array.isArray()
        ? item.year?.map((year) => moment(year).format("MM/YYYY"))?.join(" - ")
        : item.year,
    })));

    trigger();
  }, [data]);

  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <PengalamanPengelolaanProgramChild
          experience={fieldsExperience}
          data={data}
          methods={methods}
        />

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const isValid = await trigger();
                const formData = methods.getValues();
                setProgramExperienceState((prevState) => ({
                  ...prevState,
                  programme: formData,
                }));
                submitData(formData);
              }}
            >
              <BiCheck className="text-[20px] text-[#FFFFFF]" />
              Verifikasi Selesai
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default PengalamanPengelolaanProgram;
