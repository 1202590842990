import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil, BiSolidSave } from "react-icons/bi";
import { RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import { getFile } from "services/danaProgram/fileService";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const SupportingDocumentChild = ({ experience, methods, data }) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();

  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditVerification, setIsEditVerification] = useState(false);

  const toogleIsEdit = () => setIsEdit(!isEdit);
  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  const handleClick = (fileUrl) => {
    window.open(fileUrl, "_blank");
  };

  useEffect(() => {
    for (let key in data) {
      if (key.includes("approval_document")) {
        methods.setValue(key, data[key]);
      }
    }
  }, [data]);

  useEffect(() => {
    experience.map((field) => {
      if (field.key.includes("description")) {
        methods.setValue(field.key, field.data);
      }
    });
  }, [experience]);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between items-center ">
          <div>Verifikasi Dokumen Pendukung</div>
          {isEdit ? (
            <Button
              type="button"
              className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
              onClick={toogleIsEdit}
            >
              <BiSolidSave />
              Simpan Perubahan
            </Button>
          ) : (
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
              onClick={toogleIsEdit}
            >
              <BiPencil />
              Ubah
            </Button>
          )}
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col justify-center items-center gap-[24px]">
          {experience?.map((field) => {
            return (
              <div
                key={field.id}
                className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full"
              >
                <div className="border-[2px] border-[#D0D5DD] p-4 rounded-2xl w-full">
                  <div className="flex gap-4">
                    <div>
                      <div className="bg-[#EEFFF4] rounded-full p-4">
                        <RiFile2Line className="text-primary-600 text-[28px]" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="font-medium">{field.documentName}</span>
                      <span>{field.size} MB</span>
                      <span
                        onClick={() => handleClick(field.url)}
                        style={{
                          cursor: "pointer",
                          color: "#00BD52",
                        }}
                        className="font-semibold text-primary-600"
                      >
                        Lihat Dokumen
                      </span>
                    </div>
                  </div>
                </div>
                <div className="space-y-2 p-4">
                  {field.notes === undefined ? null : (
                    <InputForm
                      controllerName={"desc_" + field.key}
                      className={`py-2 border-none w-full rounded-md flex-1`}
                      label={"Uraian Singkat Terkait Isi dokumen"}
                      placeholder={"Uraian Singkat Terkait Isi dokumen"}
                      textArea
                      readOnly
                      disabled={true}
                      customValue={field.notes}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"status_approval_document"}
          nameNotes={"notes_approval_document"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={
            errors?.status_approval_document
              ? errors?.status_approval_document
              : false
          }
        />
      </CardBody>
    </Card>
  );
};

export default SupportingDocumentChild;
