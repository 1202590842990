import {
  Button,
  Card,
  CardBody,
  CardForm,
  CardHeader,
  InputForm,
  InputFormRadio,
  Select,
  TitleText,
} from "components";
import Badge from "components/atoms/Badge";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import moment from "moment";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { RiFile2Line, RiInformationLine } from "react-icons/ri";
import { useNavigate } from "react-router";

export const optionsCardForm = [
  {
    label: "Alat 1",
  },
  {
    label: "Alat 2",
  },
];

const PPN = ({ tools, datas, isEdit = false }) => {
  const { watch, getValues } = useFormContext();

  return (
    <div className="flex flex-col">
      <QuestionBadge text="Apakah Lembaga/ Institusi/ Perusahaan Anda Termasuk ke Dalam pengusaha Kena Pajak (PKP)?" />
      <InputFormRadio
        controllerName={`is_pkp`}
        className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
        values={[
          { value: true, label: "Ya" },
          { value: false, label: "Tidak" },
        ]}
        disabled={!isEdit}
      />
      {datas?.is_pkp === true && (
        <>
          <div className="w-full mt-4 flex gap-2 items-center border border-[color:var(--blue-light-300,#7CD4FD)] backdrop-blur-2xl border-solid bg-[#F5FBFF] p-4  rounded-lg text-[#026AA2] ">
            <RiInformationLine />
            Laporan PPN selama 3 bulan terakhir
          </div>
          {tools[1]?.ppn_report?.map((field, i) => {
            return (
              <>
                <div className="mt-[20px]" key={field.id}>
                  <CardForm
                    label={`Bulan ${tools[1]?.ppn_report?.[i].month} ${tools[1]?.ppn_report?.[0].year}`}
                    buttonCollapse={true}
                  >
                    <div className="space-y-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <InputForm
                          controllerName={`tools.${1}.ppn_report.${i}.no_report`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Nomor Laporan"}
                          placeholder={"Nomor Laporan"}
                          disabled={!isEdit}
                        />
                        <InputForm
                          controllerName={`tools.${1}.ppn_report.${i}.no_description`}
                          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
                          label={"Deskripsi"}
                          placeholder={"Deskripsi"}
                          disabled={!isEdit}
                          textArea={true}
                        />
                      </div>
                    </div>
                  </CardForm>
                </div>
              </>
            );
          })}
        </>
      )}

      <div className="mt-6 border border-gray"></div>

      <div className="mt-4">
        <QuestionBadge text="Apakah Lembaga/ Institusi/ Perusahaan Anda Melakukan Aktivitas Perpajakan yang Mendapatkan Fasilitas Dibebaskan?" />
        <InputFormRadio
          controllerName={`is_tax_activity`}
          className={`py-2 px-4 mt-2 border w-full rounded-md flex-1 border-[#D0D5DD]`}
          values={[
            { value: true, label: "Ya" },
            { value: false, label: "Tidak" },
          ]}
          disabled={!isEdit}
        />
      </div>
    </div>
  );
};

export default PPN;
