/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumbs } from "components";
import { useState } from "react";
import { useNavigate } from "react-router";
import DokumenPendukung from "./DokumenPendukung";
import PeralatanPerlengkapan from "./InformasiRekeningKepemilikan";
import InformasiKeuanganLembaga from "./InformasiKeuanganLembaga";
import Pajak from "./Pajak";
import Procurement from "./Procurement";

import { useMutation, useQuery } from "@tanstack/react-query";
import {
  financialProfileSubmit,
  getFinancialProfile,
} from "services/danaProgram/danaProgramService";
import { useEffect } from "react";
import InformasiRekeningKepemilikan from "./InformasiRekeningKepemilikan";
import { useForm } from "react-hook-form";

import {
  getGeneralProfile,
  submitGeneralProfile,
} from "services/danaProgram/profilUmum";
import { FinancialProfileProvider } from "./FinancialProfileState";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { findKeyName } from "helpers";

const ProfilKeuangan = () => {
  const navigate = useNavigate();
  const [isProfit, setIsProfit] = useState();
  let id_financialPlan = JSON.parse(
    sessionStorage.getItem("idData")
  ).financial_profile;
  const userSelector = useSelector((state) => state?.auth?.user || {});

  const [activeTab, setActiveTab] = useState(1);
  const [formData, setFormData] = useState({
    step_1: null,
    step_2: null,
    step_3: null,
    step_4: null,
    step_5: null,
  });

  const [isFetching, setIsFetching] = useState(true);
  const [data, setData] = useState();
  const links = [
    {
      label: "Daftar Lemtara",
      path: "/verification",
    },
    {
      label: "Detail",
      path: "/verification/Detail",
    },
    {
      label: "Profil Keuangan",
    },
  ];

  const TabList = [
    {
      label: "Dokumen Pendukung",
      id: 1,
    },
    {
      label: "Informasi Rekening & Daftar Kepemilikan",
      id: 2,
    },
    {
      label: "Informasi Keuangan Lembaga",
      id: 3,
    },
    {
      label: "Pajak",
      id: 4,
    },
    {
      label: "Procurement",
      id: 5,
    },
  ];

  const mutation = useMutation({
    mutationFn: async () => {
      try {
        const promise = await getFinancialProfile(id_financialPlan);
        setData(promise.data.data);
        setIsFetching(false);
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
  });

  const id = JSON.parse(sessionStorage.getItem("idData"))?.general_profile; // from iqbal

  const useQueryProfileUmum = (id) => {
    return useQuery(["list-profile-umum"], () => getGeneralProfile({ id }));
  };
  const { data: profileUmumData } = useQueryProfileUmum(id);

  useEffect(() => {
    if (
      profileUmumData?.data?.data?.general_information?.institution_type?.label?.includes(
        "Non"
      )
    ) {
      setIsProfit(false);
    } else {
      setIsProfit(true);
    }
  }, [profileUmumData]);
  const methods = useForm();

  useEffect(() => {
    mutation.mutate();
  }, []);

  const consumeAPI = useMutation({
    mutationFn: async (params) => {
      try {
        return await financialProfileSubmit(params);
      } catch (error) {
        console.error(error, error.response?.message);
        enqueueSnackbar(error.response.data.message, {
          autoHideDuration: 2500,
          variant: "error",
        });
      }
    },
    onSuccess: () => {
      sessionStorage.removeItem("FinancialProfileContext");
      enqueueSnackbar({
        message: "Success",
        variant: "success",
      });
      window.scrollTo({ behavior: "smooth", top: 0 });
      navigate("/verification/Detail");
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const submitData = (arr) => {
    let FinancialProfileState = JSON.parse(
      sessionStorage.getItem("FinancialProfileContext")
    );
    let DocumentSupport = data?.profile_finance_document;

    let params = {
      _id: data?._id,
      user_id: data?.user_id,
      status: "VERIFIED",
      profile_finance_document: {
        ...DocumentSupport,
        completion_description:
          FinancialProfileState?.document_support?.completion_description ??
          DocumentSupport?.completion_description,
        completion_document:
          FinancialProfileState?.document_support?.completion_document
            ?.fileId ?? DocumentSupport?.completion_document,
        contract_description:
          FinancialProfileState?.document_support?.contract_description ??
          DocumentSupport?.contract_description,
        contract_document:
          FinancialProfileState?.document_support?.contract_document?.fileId ??
          DocumentSupport?.contract_document,
        document_support: findKeyName(
          FinancialProfileState?.document_support,
          "document_support_1"
        )
          ? [
              FinancialProfileState?.document_support?.document_support_1
                ?.fileId,
              FinancialProfileState?.document_support?.document_support_2
                ?.fileId,
            ]
          : DocumentSupport?.document_support,
        npwp_company_document:
          FinancialProfileState?.document_support?.npwp_company_document
            ?.fileId ?? DocumentSupport?.npwp_company_document,
        other_document:
          FinancialProfileState?.document_support?.other_document?.fileId ??
          DocumentSupport?.other_document,
        payment_evidence_document:
          FinancialProfileState?.document_support?.payment_evidence_document
            ?.fileId ?? DocumentSupport?.payment_evidence_document,
        pkp_report_document:
          FinancialProfileState?.document_support?.pkp_report_document
            ?.fileId ?? DocumentSupport?.pkp_report_document,
        pph_report_document:
          FinancialProfileState?.document_support?.pph_report_document
            ?.fileId ?? DocumentSupport?.pph_report_document,
        ppn_report_document:
          FinancialProfileState?.document_support?.ppn_report_document
            ?.fileId ?? DocumentSupport?.ppn_report_document,
        skt_document:
          FinancialProfileState?.document_support?.skt_document?.fileId ??
          DocumentSupport?.skt_document,
        sop_description:
          FinancialProfileState?.document_support?.sop_description ??
          DocumentSupport?.sop_description,
        sop_document:
          FinancialProfileState?.document_support?.sop_document?.fileId ??
          DocumentSupport?.sop_document,
        spt_report_document:
          FinancialProfileState?.document_support?.spt_report_document
            ?.fileId ?? DocumentSupport?.spt_report_document,
        reason: FinancialProfileState?.document_support?.reason,
        result_verification:
          FinancialProfileState?.document_support?.result_verification,
      },
      step_1: {
        _id: data?.step_1?._id,
        profile_finance_id: data?.step_1?.profile_finance_id,
        account: {
          ...data?.step_1?.account,

          reason: FinancialProfileState?.step_1?.reason,
          result_verification:
            FinancialProfileState?.step_1?.result_verification,
        },
        stock_ownership: {
          ...data?.step_1?.stock_ownership,
          reason: FinancialProfileState?.step_2?.stock_ownership?.reason,
          result_verification:
            FinancialProfileState?.step_2?.stock_ownership?.result_verification,
        },
      },
      step_2: {
        _id: data?.step_2?._id,
        profile_finance_id: data?.step_1?.profile_finance_id,
        year_range: data?.step_2?.year_range,
        financial_report:
          FinancialProfileState?.step_2?.tools[0]?.financial_report ??
          data?.step_2?.financial_report,
      },
      step_3: {
        _id: data?.step_3?._id,
        profile_finance_id: data?.step_1?.profile_finance_id,
        ...data?.step_3,
        document_support: data?.step_3?.document_support
          ? data?.step_3?.document_support
          : [],
        tax: {
          ...data?.step_3?.tax,
          reason: FinancialProfileState?.step_3?.reason,
          result_verification:
            FinancialProfileState?.step_3?.result_verification,
        },
      },
      step_4: {
        _id: data?.step_4?._id,
        profile_finance_id: data?.step_1?.profile_finance_id,
        document_support: data?.step_4?.document_support,
        institutional_experience: {
          ...data?.step_4?.institutional_experience,
          reason: FinancialProfileState?.document_support?.reason,
          result_verification:
            FinancialProfileState?.document_support?.result_verification,
        },
      },
    };

    console.log("p", FinancialProfileState, data);

    consumeAPI.mutateAsync(params);
  };

  return (
    <FinancialProfileProvider>
      <div className="space-y-6">
        {!isFetching && (
          <>
            <Breadcrumbs items={links} />
            <div className="tabs">
              {TabList.map((data) => (
                <a
                  className={`tab text-[14px] px-8 tab-bordered ${
                    activeTab === data.id
                      ? "border-b-primary-600 text-primary-600 bg-primary-100 font-bold"
                      : ""
                  } hover:text-primary-600 hover:border-primary-700`}
                  onClick={() => setActiveTab(data.id)}
                >
                  {data.label}
                </a>
              ))}
            </div>
            <div>
              {activeTab === 1 && (
                <DokumenPendukung
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  data={data?.profile_finance_document}
                  setFormData={setFormData}
                  draftData={formData?.step_1}
                />
              )}
              {activeTab === 2 && (
                <InformasiRekeningKepemilikan
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  data={data?.step_1}
                  setFormData={setFormData}
                  isProfit={isProfit}
                />
              )}
              {activeTab === 3 && (
                <InformasiKeuanganLembaga
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  data={data?.step_2}
                  setFormData={setFormData}
                  isProfit={isProfit}
                />
              )}
              {activeTab === 4 && (
                <Pajak
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  data={data?.step_3}
                  setFormData={setFormData}
                  isProfit={isProfit}
                />
              )}
              {activeTab === 5 && (
                <Procurement
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  data={data?.step_4}
                  submitData={submitData}
                />
              )}
            </div>
          </>
        )}
      </div>
    </FinancialProfileProvider>
  );
};

export default ProfilKeuangan;
