import { yupResolver } from "@hookform/resolvers/yup";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import SupportingDocument from "./SupportingDocument";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { useMutation } from "@tanstack/react-query";

const SupportingDocuments = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {},
}) => {
  const validationSchema = useMemo(() => Yup.object().shape({
    resource_document: Yup.object().shape({
      status_approval: Yup.string().required('status approval is required'),
      notes_approval: Yup.string().nullable(true),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      resource_document: {
        status_approval: "APPROVED"
      }
    }
  })

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  useEffect(() => {
    if (data.resource_document) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (["structure_institution_documents","job_description_documents"].includes(keys)) {
            onLoadFile.mutate({ data: data[key][keys], key: `${key}.${keys}`, array: false })
          } else if ([
            "program_personnel_education_certificate_documents",
            "program_personnel_resume_documents",
            "finance_personnel_education_certificate_documents",
            "finance_personnel_resume_documents",
            "safeguard_personnel_education_certificate_documents",
            "safeguard_personnel_resume_documents",
            "other_personnel_education_certificate_documents",
            "other_personnel_resume_documents", "proof_of_ownership_documents"
          ].includes(keys)) {
            // the data is dynamic, sometimes [[]] or ["uuid"] or even [null], so data check is needed
            if (data[key][keys] && typeof data[key][keys] === "string") {
              onLoadFile.mutate({ data: data[key][keys], key: `${key}.${keys}`, array: true })
            }
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const onLoadFile = useMutation(async ({data, key, array = false}) => {
    try {
      let newResult = [];
      if (array){
        for (const p in data) {
          if(data[p] && (data[p] !== "null" && data[p] !== "")){
            let reportResult = await getFile(data[p])
            reportResult = {
              url: reportResult?.data?.data?.url,
              id: reportResult?.data?.data?._id,
              size: Number(reportResult?.data?.data?.size) / 100000,
            };
            newResult.push({
              file: reportResult,
            })
          }
        }
      } else {
        let reportResult = await getFile(data);
        reportResult = {
          url: reportResult?.data?.data?.url,
          id: reportResult?.data?.data?._id,
          size: Number(reportResult?.data?.data?.size) / 100000,
        };
        newResult = reportResult
      }
      setValue(`${key}.showFile`, newResult)
      // clearErrors()
    } catch (error) {
      throw error;
    }
  })

  const navigate = useNavigate()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const arrStaticFile = [
    {
      key: "program_personnel_education_certificate_documents",
      name: "Sertifikat Program Personnel",
    },
    {
      key: "program_personnel_resume_documents",
      name: "CV Program Personnel",
    },
    {
      key: "finance_personnel_education_certificate_documents",
      name: "Sertifikat Finance Personnel",
    },
    {
      key: "finance_personnel_resume_documents",
      name: "CV Finance Personnel",
    },
    {
      key: "safeguard_personnel_education_certificate_documents",
      name: "Sertifikat Safeguard Personnel",
    },
    {
      key: "safeguard_personnel_resume_documents",
      name: "CV Safeguard Personnel",
    },
    {
      key: "other_personnel_education_certificate_documents",
      name: "Sertifikat Other Personnel",
    },
    {
      key: "other_personnel_resume_documents",
      name: "CV Other Personnel",
    },
    {
      key: "proof_of_ownership_documents",
      name: "Bukti Kepemilikan",
    },
  ];

  const arrStaticFile2 = [
    {
      key: "structure_institution_documents",
      keyDesc: "description_structure_institution",
      name: "Dokumen struktur organisasi Lembaga/ Instansi/ perusahaan",
    },
    {
      key: "job_description_documents",
      keyDesc: "description_job_description",
      name: "Dokumen Fungsi Organ",
    },
  ];

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['resource_document']['notes_approval'] = allValues?.resource_document?.notes_approval
    allData['resource_document']['status_approval'] = allValues?.resource_document?.status_approval

    arrStaticFile.forEach(async (e) => {
      let files = allValues["files_" + e.key];
      if (files) {
        const fileFormData = new FormData();
        fileFormData.append("file", files);
        const resFile = await fileUpload(fileFormData);
        const resDataFile = await resFile.data.data;

        allValues.profile_organization_document[e.key].showFile = {
          id: resDataFile.id,
          name: resDataFile.name,
          size: resDataFile.size,
          url: resDataFile.url,
        }
      }
    })

    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <SupportingDocument
          arrStaticFile={arrStaticFile}
          arrStaticFile2={arrStaticFile2}
        />
      </FormProvider>
      <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
        <div>
          <Button
            type="button"
            className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
            onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
          >
            <RiArrowLeftLine />
            Kembali
          </Button>
        </div>
        {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const validData = await saveData()
                if (validData)
                  setActiveTab(activeTab + 1)
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SupportingDocuments;