import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import SupportingDocument from "./SupportingDocument";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { fileUpload, getFile } from "services/danaProgram/fileService";
import { useMutation } from "@tanstack/react-query";
import { isEmpty } from "lodash";
import { useGeneralProfileContext } from "../hooks/generalProfileState";
import { isNotEmpty, removeWords } from "helpers";

const SupportingDocuments = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {},
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);
  const { generalProfileState, setGeneralProfileState } =
    useGeneralProfileContext();
  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      general_profile_document: Yup.object().shape({
        status_approval: Yup.string().required(`status approval is required`),
        notes_approval: Yup.string().nullable(true),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      general_profile_document: {
        status_approval: "APPROVED",
      },
    },
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  useEffect(() => {
    if (data.general_profile_document) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (
            [
              "proof_document_of_deed",
              "proof_document_of_nib",
              "branch_data_document",
            ].includes(keys) &&
            !isEmpty(data[key][keys])
          ) {
            onLoadFile.mutate({
              data: data[key][keys]?.id ?? data[key][keys],
              key: `${key}.${keys}`,
              array: false,
            });
          } else if (
            ["additional_document"].includes(keys) &&
            !isEmpty(data[key][keys])
          ) {
            data[key][keys].forEach((val, k) => {
              if (!isEmpty(val)) {
                onLoadFile.mutate({
                  data: val,
                  key: `${key}.${keys}.${k}`,
                  array: false,
                });
              }
            });
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
          }
        });
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const onLoadFile = useMutation(async ({ data, key, array = false }) => {
    try {
      let newResult = [];
      if (array) {
        for (const p in data) {
          let reportResult = await getFile(data[p]);
          reportResult = {
            url: reportResult?.data?.data?.url,
            id: reportResult?.data?.data?._id,
            size: Number(reportResult?.data?.data?.size) / 100000,
          };
          newResult.push({
            file: reportResult,
          });
        }
      } else {
        let reportResult = await getFile(data);
        reportResult = {
          url: reportResult?.data?.data?.url,
          id: reportResult?.data?.data?._id,
          size: Number(reportResult?.data?.data?.size) / 100000,
        };
        newResult = reportResult;
      }
      setValue(`${key}.showFile`, newResult);
      // clearErrors()
    } catch (error) {
      throw error;
    }
  });

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const arrStaticFile = [
    {
      key: "proof_document_of_deed",
      name: "Dokumen Bukti Akta Pendirian",
    },
    {
      key: "proof_document_of_nib",
      name: "Dokumen Bukti NIB",
    },
    {
      key: "branch_data_document",
      name: "Data Cabang",
    },
  ];

  const {
    fields: dataAdditionalDocument,
    append: appendDataAdditionalDocument,
    remove: removeDataAdditionalDocument,
  } = useFieldArray({
    control,
    name: "general_profile_document.additional_document.showFile",
  });

  const saveData = async (dat) => {
    const isValid = await trigger();
    const allValues = getValues();
    const { notes_approval, status_approval, ...restData } =
      data?.general_profile_document;
    allData["general_profile_document"] = {
      ...restData,
      notes_approval: allValues?.general_profile_document?.notes_approval,
      status_approval: allValues?.general_profile_document?.status_approval,
    };
    setGeneralProfileState((prevState) => ({
      ...prevState,
      general_profile_document: {
        ...allValues,
        notes_approval: allValues?.general_profile_document?.notes_approval,
        status_approval: allValues?.general_profile_document?.status_approval,
      },
    }));
    setData(allData);
    return isValid;
  };

  const uploadDocuments = async (file, key) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await fileUpload(formData);

      const resFileData = res.data.data;

      let documentsUploaded = {
        fileId: resFileData.id,
        fileName: resFileData.name,
        fileSize: resFileData.size,
        mimeType: resFileData.mimeType,
        path: resFileData.url,
      };
      methods.setValue(removeWords(key, "edited_"), documentsUploaded);
      return methods.setValue(key, documentsUploaded);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadData = async (data) => {
    for (let key in data) {
      if (key.includes("edited") && data[key]) {
        uploadDocuments(data[key], key);
      }
    }
  };

  useEffect(() => {
    if (!isEdit) {
      const formData = methods.getValues();
      uploadData(formData);
    }
  }, [isEdit]);

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <SupportingDocument
          arrStaticFile={arrStaticFile}
          dataAdditionalDocument={dataAdditionalDocument}
          appendDataAdditionalDocument={appendDataAdditionalDocument}
          removeDataAdditionalDocument={removeDataAdditionalDocument}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
        />
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() =>
                activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)
              }
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem("isLemtaraFormDisabled") !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData();
                  if (validData) setActiveTab(activeTab + 1);
                }}
              >
                Selanjutnya
                <RiArrowRightLine />
              </Button>
            </div>
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default SupportingDocuments;
