import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiCheckFill } from "react-icons/ri";
import { useNavigate } from "react-router";
import { Button } from "components";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormWorkingArea from "./Form/FormWorkingArea";
import { useSelector } from "react-redux";
import { useState } from "react";
import useProfileUmum from "../hooks/useProfileUmum";
import { useGeneralProfileContext } from "../hooks/generalProfileState";

export const optYesOrNo = [
  {
    label: "Ya",
    value: "Ya",
  },
  {
    label: "Tidak",
    value: "Tidak",
  },
];

const WorkingArea = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {},
  onSubmit = () => {},
  citiesData = [],
  refetchCities = () => {},
  districtsData = [],
  refetchDistricts = () => {},
  provincesData = [],
  refetchProvinces = () => {},
}) => {
  const { generalProfileState, setGeneralProfileState } =
    useGeneralProfileContext();
  const navigate = useNavigate();

  const masterData = useSelector((state) => state?.draftMasterData?.data || {});

  const [areaCoverageMasterData, setAreaCoverageMasterData] = useState([]);

  const [officeLocationMasterData, setOfficeLocationMasterData] = useState({
    provinsi: masterData?.provinsi,
    kotaKabupaten: masterData?.kotaKabupaten,
    kecamatan: masterData?.kecamatan,
    kelurahan: masterData?.kelurahan,
    kodePos: masterData?.kodePos,
  });

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      working_area: Yup.object().shape({
        province_id: Yup.mixed().required(),
        area_coverage: Yup.array().of(
          Yup.object().shape({
            _id: Yup.mixed(),
            // province_id: Yup.object().shape({
            //   value: Yup.mixed(),
            //   label: Yup.mixed()
            // }),
            province_id: Yup.mixed().required(),
            // city_id: Yup.object().shape({
            //   value: Yup.mixed(),
            //   label: Yup.mixed()
            // }),
            city_id: Yup.mixed().required(),
            working_area_id: Yup.mixed(),
          })
        ),
        notes_approval_area_coverage: Yup.string().nullable(true),
        status_approval_area_coverage: Yup.string().required(
          `status approval area coverage is required`
        ),
        notes_approval_working_area: Yup.string().nullable(true),
        status_approval_working_area: Yup.string().required(
          `status approal working area is required`
        ),
        province_id: Yup.mixed().required(),
        city_id: Yup.mixed().required(),
        district_id: Yup.mixed().required(),
        village_id: Yup.mixed().required(),
        zip_code: Yup.mixed().required(),
        address: Yup.string().trim().required(),
        lattitude: Yup.mixed(),
        longitude: Yup.mixed(),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      working_area: {
        status_approval_area_coverage: "APPROVED",
        status_approval_working_area: "APPROVED",
      },
    },
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const {
    fields: areaCoverageFields,
    append: appendareaCoverage,
    remove: removeareaCoverage,
  } = useFieldArray({
    control,
    name: "working_area.area_coverage",
  });

  useEffect(() => {
    refetchProvinces();
    refetchCities();
    refetchDistricts();
  }, []);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (keys === "length_branch") {
            if (data[key][keys] > 0) {
              setValue(`working_area.hadBranch`, "Ya");
            }
          } else if (keys === "area_coverage") {
            const arrTemp = [];
            for (const p in data[key][keys]) {
              arrTemp.push({
                provinsi: masterData?.provinsi,
                kotaKabupaten: masterData?.kotaKabupaten?.filter(
                  (e) => e.extraId === data[key][keys][p][`province_id`]
                ),
              });
            }
            setAreaCoverageMasterData(arrTemp);
          }
          setValue(`${key}.${keys}`, data[key][keys]);
          // console.log(`${key}.${keys}`, data[key][keys])
        });
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const saveData = async () => {
    const isValid = await trigger();
    const allValues = getValues();
    allData.working_area = allValues.working_area;
    setGeneralProfileState((prevState) => ({
      ...prevState,
      working_area: allValues.working_area,
    }));
    setData(allData);

    if (isValid) {
      onSubmit(allData);
    }
  };

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        <FormWorkingArea
          areaCoverageFields={areaCoverageFields}
          appendareaCoverage={appendareaCoverage}
          removeareaCoverage={removeareaCoverage}
          masterData={masterData}
          areaCoverageMasterData={areaCoverageMasterData}
          setAreaCoverageMasterData={setAreaCoverageMasterData}
          officeLocationMasterData={officeLocationMasterData}
          setOfficeLocationMasterData={setOfficeLocationMasterData}
          optYesOrNo={optYesOrNo}
          provinceOptions={
            provincesData
              ? provincesData.map((data) => ({
                  label: data?.namaProvinsi,
                  value: data?.id,
                  ...data,
                }))
              : []
          }
          cityOptions={
            citiesData
              ? citiesData.map((data) => ({
                  label: data?.namaKabupatenKota,
                  value: data?.id,
                  ...data,
                }))
              : []
          }
          districtOptions={
            districtsData
              ? districtsData.map((data) => ({
                  label: data?.namaKecamatan,
                  value: data?.id,
                  ...data,
                }))
              : []
          }
          methods={methods}
        />
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() =>
                activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)
              }
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem(`isLemtaraFormDisabled`) !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData();
                }}
              >
                <RiCheckFill className={`text-[23px]`} />
                Verifikasi Selesai
              </Button>
            </div>
          )}
        </div>
      </FormProvider>
    </div>
  );
};

export default WorkingArea;
