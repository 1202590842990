import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "components";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import SupportingDocumentChild from "./Form/DokumenPendukung";
import { BiCheck } from "react-icons/bi";
import { useMutation } from "@tanstack/react-query";
import { getFile } from "services/danaProgram/fileService";
import { getValue } from "@testing-library/user-event/dist/utils";

const DokumenPendukung = ({
  setActiveTab,
  activeTab = 1,
  data = null,
  setVerificationData,
  setData = () => {},
}) => {
  const navigate = useNavigate();
  const [documentData, setDocumentData] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      status_approval: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval: Yup.string().nullable(true)
    })
  })

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      resource_document: {
        status_approval: "APPROVED"
      }
    }
  })

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const getFileMutation = useMutation(async ({ id, key }) => {
    try {
      if (!Array.isArray(id)) {
        const response = await getFile(id);
        let temp = {
          key: key,
          url: response.data.data.url,
          id: response.data.data.id,
          type: response.data.data.mimeType,
          name: response.data.data.name,
          size: Math.round(parseInt(response.data.data.size) / 1000),
        };
        setDocumentData((prevState) => [...prevState, temp]);
      }
    } catch (error) {
      throw error;
    }
  });

  useEffect(() => {
    setDocumentData([]);
    if (data) {
      data?.document_support?.map((field, index) => {
        getFileMutation.mutate({
          id: field,
          key: `Laporan Safeguard ${index + 1}`,
        });
      });
      for (let key in data) {
        if (key.includes("approval")) {
          methods.setValue(key, data[key]);
        }
      }
    }
    setIsFetching(false);
    trigger()
  }, [data]);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    let allData = data;
    if(allData){

      allData['notes_approval'] = allValues?.notes_approval
      allData['status_approval'] = allValues?.status_approval
    } else {
      allData = {
        notes_approval: allValues?.notes_approval,
        status_approval: allValues?.status_approval
      }
    }

    // arrStaticFile.forEach(async (e) => {
    //   allData.safeguard_document["desc_"+e.key] = allValues?.safeguard_document["desc_"+e.key];

    //   let files = allValues["files_" + e.key];
    //   if (files) {
    //     const fileFormData = new FormData();
    //     fileFormData.append("file", files);
    //     const resFile = await fileUpload(fileFormData);
    //     const resDataFile = await resFile.data.data;

    //     allData.safeguard_document[e.key] = resDataFile.id;
    //   }
    // })

    console.log(allValues);
    console.log(allData);
    setVerificationData(allData);
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {!isFetching && (
          <SupportingDocumentChild
            safeguards={documentData}
            data={data}
            setData={setData}
            methods={methods}
          />
        )}

        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => navigate(`/verification/detail`) }
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          <div className="block md:flex items-center justify-between gap-2">
            <Button
              type="button"
              className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
              onClick={async () => {
                const validData = await saveData()
                if (validData)
                  setActiveTab(activeTab + 1)
              }}
            >
              Selanjutnya
              <RiArrowRightLine />
            </Button>
          </div>
        </div>
      </FormProvider>
    </div>
  );
};

export default DokumenPendukung;
