import { useNavigate } from "react-router";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect, useMemo, useState } from "react";
import Sdm from "./Form/Sdm";
import { Button } from "components";
import { RiArrowLeftLine, RiArrowRightLine } from "react-icons/ri";

export const optionsSumEmployeeCurrent = [
  {
    label: "Kurang Dari 10 Orang",
    value: "Kurang Dari 10 Orang",
  },
  {
    label: "10-50 Orang",
    value: "10-50 Orang",
  },
  {
    label: "50-100 Orang",
    value: "50-100 Orang",
  },
  {
    label: "Diatas 100 Orang",
    value: "Diatas 100 Orang",
  },
];

export const optionsSumEmployee = [
  {
    label: "Tidak ada",
    value: "Tidak ada",
  },
  {
    label: "Kurang Dari 10 orang",
    value: "Kurang Dari 10 orang",
  },
  {
    label: "11-30 orang",
    value: "11-30 orang",
  },
  {
    label: "31-100 orang",
    value: "31-100 orang",
  },
  {
    label: "Diatas 100 orang",
    value: "Diatas 100 orang",
  },
];

export const optionsSumRunProgram = [
  {
    label: "1-3 Orang",
    value: "1-3 Orang",
  },
  {
    label: "4-7 Orang",
    value: "4-7 Orang",
  },
  {
    label: "8-15 Orang",
    value: "8-15 Orang",
  },
  {
    label: "Diatas 15 orang",
    value: "Diatas 15 orang",
  },
];

const SumberDayaManusia = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {}
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit)

  const validationSchema = useMemo(() => Yup.object().shape({
    step_1: Yup.object().shape({
      partner_list: Yup.object().shape({
        total_employee: Yup.string().required(`total employee is required`),
        total_contract_employee: Yup.string().required(`total contract employee is required`),
        total_staff_program: Yup.string().required(`total staff program is required`),
        program_personnel: Yup.array().of(
          Yup.object().shape({
            identity_number: Yup.number().typeError('Nik harus angka').required('Nik wajib di isi'),
            name: Yup.string().required(),
            employee_status: Yup.mixed(),
            dob: Yup.string().required(),
            education: Yup.mixed(),
            position: Yup.mixed(),
            work_experience: Yup.string().required(),
            skills: Yup.string().required(),
          })
        ),
        finance_personnel: Yup.array().of(
          Yup.object().shape({
            identity_number: Yup.number().typeError('Nik harus angka').required('Nik wajib di isi'),
            name: Yup.string().required(),
            employee_status: Yup.mixed(),
            dob: Yup.string().required(),
            education: Yup.mixed(),
            position: Yup.mixed(),
            work_experience: Yup.string().required(),
            skills: Yup.string().required(),
          })
        ),
        safeguard_personnel: Yup.array().of(
          Yup.object().shape({
            identity_number: Yup.number().typeError('Nik harus angka').required('Nik wajib di isi'),
            name: Yup.string().required(),
            employee_status: Yup.mixed(),
            dob: Yup.string().required(),
            education: Yup.mixed(),
            position: Yup.mixed(),
            work_experience: Yup.string().required(),
            skills: Yup.string().required(),
          })
        ),
        // other_personnel: Yup.array().of(
        //   Yup.object().shape({
        //     identity_number: Yup.number().typeError('Nik harus angka').required('Nik wajib di isi'),
        //     name: Yup.string().required(),
        //     employee_status: Yup.mixed(),
        //     dob: Yup.string().required(),
        //     education: Yup.mixed(),
        //     position: Yup.mixed(),
        //     work_experience: Yup.string().required(),
        //     skills: Yup.string().required(),
        //   })
        // ),
        work_performance: Yup.array().of(
          Yup.object().shape({
            type_work_performance: Yup.mixed(),
            reward: Yup.string(),
          })
        ),
        punishment: Yup.array().of(
          Yup.object().shape({
            type_punishment: Yup.string(),
            reward: Yup.string(),
          })
        ),
      }),
      status_approval_personnel: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval_personnel: Yup.string().nullable(true),
      status_approval_reward_punishment: Yup.string().required(`Hasil verifikasi wajib diisi`),
      notes_approval_reward_punishment: Yup.string().nullable(true),
    })
  }));

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      step_1: {
        status_approval_personnel: "APPROVED",
        status_approval_reward_punishment: "APPROVED"
      }
    }
  });

  const {
    formState: { errors, isValid },
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  const {
    fields: fieldsProgramPersonnel,
    appends: appendsProgramPersonnel,
    remove: removeProgramPersonnel
  } = useFieldArray({
    control,
    name: "step_1.partner_list.program_personnel"
  })

  const {
    fields: fieldsFinancePersonnel,
    appends: appendsFinancePersonnel,
    remove: removeFinancePersonnel
  } = useFieldArray({
    control,
    name: "step_1.partner_list.finance_personnel"
  })

  const {
    fields: fieldsSafeguardPersonnel,
    appends: appendsSafeguardPersonnel,
    remove: removeSafeguardPersonnel
  } = useFieldArray({
    control,
    name: "step_1.partner_list.safeguard_personnel"
  })

  const {
    fields: fieldsOtherPersonnel,
    appends: appendsOtherPersonnel,
    remove: removeOtherPersonnel
  } = useFieldArray({
    control,
    name: "step_1.partner_list.other_personnel"
  })

  const {
    fields: fieldsWorkPerformance,
    appends: appendsWorkPerformance,
    remove: removeWorkPerformance
  } = useFieldArray({
    control,
    name: "step_1.partner_list.work_performance"
  })

  const {
    fields: fieldsPunishment,
    appends: appendsPunishment,
    remove: removePunishment
  } = useFieldArray({
    control,
    name: "step_1.partner_list.punishment"
  })

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((keys) => {
          if (keys === `partner_list`){
            Object.keys(data[key][keys]).forEach((keyss) => {
              if (keyss === "total_employee"){
                if (optionsSumRunProgram.map((e) => e?.value).includes(data[key][keys][keyss])){
                  setValue(`${key}.${keys}.${keyss}`, data[key][keys][keyss]);
                }
              } else if (keyss === "total_contract_employee"){
                if (optionsSumEmployee.map((e) => e?.value).includes(data[key][keys][keyss])){
                  setValue(`${key}.${keys}.${keyss}`, data[key][keys][keyss]);
                }
              } else if (keyss === "total_staff_program"){
                if (optionsSumEmployeeCurrent.map((e) => e?.value).includes(data[key][keys][keyss])){
                  setValue(`${key}.${keys}.${keyss}`, data[key][keys][keyss]);
                }
              } else {
                setValue(`${key}.${keys}.${keyss}`, data[key][keys][keyss]);
              }
              // console.log(`${key}.${keys}.${keyss}`, data[key][keys][keyss])
            })
          } else {
            setValue(`${key}.${keys}`, data[key][keys]);
            // console.log(`${key}.${keys}`, data[key][keys])
          }
        })
      });
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger()
    const allValues = getValues()
    allData['step_1'] = allValues.step_1
    setData(allData)
    return isValid;
  }

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {/* <LandasanHukum /> */}
        <Sdm
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          fieldsProgramPersonnel={fieldsProgramPersonnel}
          appendsProgramPersonnel={appendsProgramPersonnel}
          removeProgramPersonnel={removeProgramPersonnel}
          fieldsFinancePersonnel={fieldsFinancePersonnel}
          appendsFinancePersonnel={appendsFinancePersonnel}
          removeFinancePersonnel={removeFinancePersonnel}
          fieldsSafeguardPersonnel={fieldsSafeguardPersonnel}
          appendsSafeguardPersonnel={appendsSafeguardPersonnel}
          removeSafeguardPersonnel={removeSafeguardPersonnel}
          fieldsOtherPersonnel={fieldsOtherPersonnel}
          appendsOtherPersonnel={appendsOtherPersonnel}
          removeOtherPersonnel={removeOtherPersonnel}
          fieldsWorkPerformance={fieldsWorkPerformance}
          appendsWorkPerformance={appendsWorkPerformance}
          removeWorkPerformance={removeWorkPerformance}
          fieldsPunishment={fieldsPunishment}
          appendsPunishment={appendsPunishment}
          removePunishment={removePunishment}
          optionsSumEmployeeCurrent={optionsSumRunProgram}
          optionsSumEmployee={optionsSumEmployee}
          optionsSumRunProgram={optionsSumEmployeeCurrent}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"verificatorRecomendation"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() => activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)}
            >
              <RiArrowLeftLine/>
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData()
                  if (validData)
                    setActiveTab(activeTab + 1)
                }}
              >
                Selanjutnya
                <RiArrowRightLine/>
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  );
}

export default SumberDayaManusia;