import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { RiArrowLeftLine, RiArrowRightLine, RiFile2Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  Breadcrumbs,
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import ProfileInformation from "./Form/ProfileInformation";
import ResponsibleInformation from "./Form/ResponsibleInformation";
import SocialMediaInformation from "./Form/SocialMediaInformation";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SupportingDocument from "./Form/SupportingDocument";
import * as yup from "yup";
import { FormModal } from "components/molecules/Modal";
import { useSelector } from "react-redux";
import { useGeneralProfileContext } from "../hooks/generalProfileState";

const GeneralInformation = ({
  setActiveTab,
  activeTab = 1,
  data = {},
  allData = {},
  setData = () => {},
}) => {
  const masterData = useSelector((state) => state?.draftMasterData?.data || {});
  const { generalProfileState, setGeneralProfileState } =
    useGeneralProfileContext();

  const [isEdit, setIsEdit] = useState(false);
  const toogleIsEdit = () => setIsEdit(!isEdit);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      general_information: yup.object().shape({
        lemtara_name: yup.string().required(`Nama Lemtara wajib diisi`),
        institution_type: yup.mixed().required(`Jenis Lembaga wajib diisi`),
        intervention: yup.mixed(),
        email: yup.string().email().required("Email wajib diisi"),
        phone: yup
          .number()
          .typeError("phone harus angka")
          .required("No Telepon wajib diisi"),
        contact_person: yup.string().required("Nama Narahubung wajib diisi"),
        directors: yup.string().required("Nama Direktur/Pimpinan wajib diisi"),
        email_pic: yup.string().email().required("Email wajib diisi"),
        phone_pic: yup
          .number()
          .typeError(`No Telepon harus angka`)
          .required("No Telepon wajib diisi"),
        general_social_media: yup.object().shape({
          website: yup.string().required("Website wajib diisi"),
          status_approval_social_media: yup
            .string()
            .required("status approval social media is required"),
          notes_approval_social_media: yup.string().nullable(true),
        }),
        status_approval_general_information: yup
          .string()
          .required(`status approval general information is required`),
        notes_approval_general_information: yup.string().nullable(true),
        status_approval_general_pic: yup
          .string()
          .required(`status approval general pic is required`),
        notes_approval_general_pic: yup.string().nullable(true),
        recommender_notes: yup.string(),
      }),
    });
  });

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      general_information: {
        status_approval_general_information: "APPROVED",
      },
    },
  });

  const {
    formState: { errors, isValid },
    reset,
    setValue,
    control,
    watch,
    getValues,
    register,
    unregister,
    clearErrors,
    trigger,
  } = methods;

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      reset(data);
      trigger();
    }
  }, [data, setValue, methods]);

  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);

  const saveData = async () => {
    const isValid = await trigger();
    const allValues = getValues();

    allData.general_information = allValues.general_information;

    setGeneralProfileState((prevState) => ({
      ...prevState,
      general_information: allValues.general_information,
    }));

    setData({
      ...allData,
      ...allValues,
    });
    return isValid;
  };

  return (
    <div className="space-y-6">
      <FormProvider {...methods}>
        {/* <SupportingDocument/> */}
        <ProfileInformation
          saveData={saveData}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
          masterData={masterData}
        />
        <ResponsibleInformation
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
        />
        <SocialMediaInformation
          openModal={() => setIsOpenModal(true)}
          isEdit={isEdit}
          toogleIsEdit={toogleIsEdit}
        />
        {/* <Card>
          <CardHeader>
            <div>Rekomendasi Verificator</div>
          </CardHeader>
          <CardBody>
            <div className="space-y-5">
              <div className="space-y-2">
                <TitleText className="font-bold">
                  Rekomendasi Verificator
                </TitleText>
                <InputForm
                  controllerName={"general_information.recommender_notes"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={''}
                  placeholder={'Verifikasi Remokendasi'}
                  textArea
                />
              </div>
            </div>
          </CardBody>
        </Card> */}
        <div className="block md:flex items-center justify-between px-6 py-5 border border-gray-200 rounded-lg mt-4 bg-white mb-10">
          <div>
            <Button
              type="button"
              className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300 w-40"
              onClick={() =>
                activeTab === 1 ? navigate(-1) : setActiveTab(activeTab - 1)
              }
            >
              <RiArrowLeftLine />
              Kembali
            </Button>
          </div>
          {sessionStorage.getItem("isLemtaraFormDisabled") !== "true" && (
            <div className="block md:flex items-center justify-between gap-2">
              <Button
                type="button"
                className="text-white border-primary-600 bg-primary-600 hover:bg-primary-700 hover:border-primary-700"
                onClick={async () => {
                  const validData = await saveData();
                  if (validData) {
                    setActiveTab(activeTab + 1);
                  }
                }}
              >
                Selanjutnya
                <RiArrowRightLine />
              </Button>
            </div>
          )}
        </div>
        {/* form modal */}
        {/* <FormModal
          title={``}
          open={isOpenModal}
          onClose={() => setIsOpenModal(!isOpenModal)}
          onSubmit={() => {
            setIsOpenModal(!isOpenModal)
          }}
          positiveConfirm={`Ya`}
          negativeConfirm={`Tidak`}
          size={`w-3/12`}
        /> */}
      </FormProvider>
    </div>
  );
};

export default GeneralInformation;
