import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import { BiPencil } from "react-icons/bi";
import AtomSelect from "components/atoms/Select";
import { useFormContext } from "react-hook-form";
import OptionRadioVerificatorBadge from "components/molecules/Badge/OptionRadioVerificatorBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useQuery } from "@tanstack/react-query";
import { getInstitutionTypes } from "services/danaProgram/masterDataService";

const ProfileInformation = ({
  saveData = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  masterData = {},
}) => {
  const navigate = useNavigate();

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const { data: institutionTypes } = useQuery({
    queryKey: ["institution_type"],
    queryFn: getInstitutionTypes,
    select: (data) => data.data?.data?.map((item) => ({
      value: item.id,
      label: item.nama,
    })),
  });

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Informasi Umum</div>
          <div className="flex-1 text-right">
            {sessionStorage.getItem("isLemtaraFormDisabled") !== "true" &&
              (isEdit ? (
                <Button
                  type="button"
                  className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                  onClick={toogleIsEdit}
                >
                  <BiPencil />
                  Simpan Perubahan
                </Button>
              ) : (
                <Button
                  type="button"
                  className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                  onClick={toogleIsEdit}
                >
                  <BiPencil />
                  Ubah
                </Button>
              ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputForm
            controllerName={"general_information.lemtara_name"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Nama Lemtara"}
            placeholder={"Nama Lemtara"}
            required
            disabled={!isEdit}
          />
          <AtomSelect
            controllerName={"general_information.institution_type"}
            options={institutionTypes}
            className="basic-single mt-2"
            label="Jenis Lembaga"
            required
            returnObject={
              typeof watch("general_information.institution_type") === "object"
            }
            disable={!isEdit}
          />
          <div className="col-span-2">
            <AtomSelect
              controllerName={"general_information.intervention"}
              options={[
                {
                  value: "05b7ee95-a067-4272-bfe8-c5af7e06eb8d",
                  label: "Pengendalian Perubahan Iklim",
                  deskripsi: "Pengendalian Perubahan Iklim",
                },
                {
                  value: "da06625c-7856-46b5-8140-df5a06f30190",
                  label: "Pengelolaan Hutan Berkelanjutan",
                  deskripsi: "Pengelolaan Hutan Berkelanjutan",
                },
                {
                  value: "47f517d3-4044-4055-9359-562e1c02670d",
                  label:
                    "Pengendalian Kebakaran Hutan dan Lahan, serta Pemulihan Lahan Gambut",
                  deskripsi:
                    "Pengendalian Kebakaran Hutan dan Lahan, serta Pemulihan Lahan Gambut",
                },
                {
                  value: "4d73213c-8e36-4c7e-8d70-fa04ef2ee8d1",
                  label: "Perhutanan Sosial dan Kemitraan Lingkungan",
                  deskripsi: "Perhutanan Sosial dan Kemitraan Lingkungan",
                },
                {
                  value: "e99b5847-88a1-429b-8bfb-56eadc48fcbf",
                  label:
                    "Rehabilitasi Hutan dan Lahan, serta Kegiatan Pendukung Lainnya",
                  deskripsi:
                    "Rehabilitasi Hutan dan Lahan, serta Kegiatan Pendukung Lainnya",
                },
                {
                  value: "cd0c5e29-b2b4-40a1-b6d1-d34254214cea",
                  label: "Konservasi Keanekaragaman Hayati dan Ekosistem",
                  deskripsi: "Konservasi Keanekaragaman Hayati dan Ekosistem",
                },
              ]}
              className="basic-single mt-2"
              label="Pilih Fokus Isu Atau Intervansi Lembaga"
              multiple
              disable={!isEdit}
            />
          </div>
          <InputForm
            controllerName={"general_information.email"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"Email"}
            placeholder={"Email"}
            required
            disabled={!isEdit}
          />
          <InputForm
            controllerName={"general_information.phone"}
            className={`py-2 px-4 mt-2 border w-full rounded-md flex-1`}
            label={"No Telepon"}
            placeholder={"No Telepon"}
            required
            disabled={!isEdit}
          />
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"general_information.status_approval_general_information"}
          nameNotes={"general_information.notes_approval_general_information"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={
            errors?.general_information?.status_approval_general_information
              ? errors?.general_information?.status_approval_general_information
              : false
          }
        />
      </CardBody>
    </Card>
  );
};

export default ProfileInformation;
