import {
  Button,
  Card,
  CardBody,
  CardHeader,
  InputForm,
  TitleText,
} from "components";
import AtomSelect from "components/atoms/Select";
import OptionCheckboxBadge from "components/molecules/Badge/OptionCheckboxBadge";
import OptionRadioBadge from "components/molecules/Badge/OptionRadioBadge";
import QuestionBadge from "components/molecules/Badge/QuestionBadge";
import RadioBadge from "components/molecules/Badge/RadioBadge";
import RadioVerificationBadge from "components/molecules/Badge/RadioVerificationBadge";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiPencil } from "react-icons/bi";
import { useNavigate } from "react-router";

const FormInstitutionalPlan = ({
  openModal = () => console.log(`open`),
  isEdit = false,
  toogleIsEdit = () => {},
  strategyMaster = [],
  appendstrategyMaster = () => {},
  removestrategyMaster = () => {},
  masterData = {},
  optTimeCreatePlanStrategic = [],
  optYesOrNo = []
}) => {

  const navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  const {
    watch,
    getValues,
    formState: { errors, isValid },
  } = useFormContext();
  const [isEditVerification, setIsEditVerification] = useState(false);
  const isInit = useRef(false);

  const planStrategyOrganization = watch(`institutional_plan.plan_strategy_organization`);
  // const planStrategyOrganization = masterData?.rencanaStrategis;

  useEffect(() => {
    if (planStrategyOrganization?.length && !isInit.current) {
      setPlans(planStrategyOrganization);
      isInit.current = true;
    }
  }, [planStrategyOrganization]);

  const toogleIsEditVerification = () =>
    setIsEditVerification(!isEditVerification);

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-center items-center">
          <div className="flex-1">Rencana Kelembagaan</div>
          <div className={"flex-1 text-right"}>
            {sessionStorage.getItem('isLemtaraFormDisabled') !== "true" && (isEdit ? (
              <Button
                type="button"
                className="bg-[#00BD52] hover:bg-[#00BD52] text-[#FFFFFF]"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Simpan Perubahan
              </Button>
            ) : (
              <Button
                type="button"
                className="text-gray-700 border-gray-300 bg-white hover:bg-gray-300"
                onClick={toogleIsEdit}
              >
                <BiPencil />
                Ubah
              </Button>
            ))}
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="flex flex-col">
          <QuestionBadge text="Bagaimana Cara Anda Menyusun Rencana Strategis Lembaga Anda?" />
          {plans?.map((e, i) => {
            return (
              <div className={i === 0 ? `mt-[20px]` : `mt-[11px]`}>
                <OptionCheckboxBadge
                  label={typeof e === "object" ? e?.option_title : e}
                  active={planStrategyOrganization?.some((el) => el === e)}
                  // active={e}
                  value={e}
                  key={i}
                  name={`institutional_plan.plan_strategy_organization`}
                  disabled={!isEdit}
                />
              </div>
            );
          })}
          {watch(`institutional_plan.plan_strategy_organization`)?.find(
            (e) => e.option_title === "d819ce1c-17a1-4761-b160-139316d78bdf"
          ) && (
            <>
              <div className={`mt-[11px] text-[16px] font-[600]`}>
                * Jika tidak ada di daftar pilihan, silahkan pilih opsi
                "Lain-lain" dan jelaskan Rencana Strategis Lembaga Anda dibawah
                ini
              </div>
              <div className={`mt-[11px]`}>
                <TitleText className="font-bold">Jelaskan</TitleText>
                <InputForm
                  controllerName={"institutional_plan.plan_desc"}
                  className={`py-2 border w-full rounded-md flex-1`}
                  label={""}
                  placeholder={"Jelaskan"}
                  rows="5"
                  textArea
                  disabled={!isEdit}
                />
              </div>
            </>
          )}
          <div className={"mt-[17px]"}>
            <QuestionBadge text="Apakah di lembaga Anda ada pemutus rencana strategis?" />
          </div>
          <RadioBadge
            arrData={optYesOrNo}
            isEdit={isEdit}
            name={`institutional_plan.decisionMaker`}
            className={`mt-[11px] flex gap-[18px] w-1/2`}
            errors={errors?.institutional_plan?.decisionMaker ? errors?.institutional_plan?.decisionMaker : false}
          />
          <div className={`mt-[11px]`}>
            {strategyMaster?.length > 0 &&
              strategyMaster?.map((e, i) => (
                <AtomSelect
                  controllerName={`institutional_plan.strategy_master.${i}.position`}
                  options={masterData?.jabatan}
                  className="basic-single mt-2"
                  label={`Jabatan ${i + 1}`}
                  disable={!isEdit}
                  // returnObject={false}
                  returnObject={
                    typeof getValues(`institutional_plan.strategy_master.${i}.position`) === "object"
                  }
                />
              ))}
          </div>
          <div className={`mt-[11px]`}>
            <AtomSelect
              controllerName={"institutional_plan.hirarki_position"}
              options={masterData?.jabatan}
              className="basic-single mt-3"
              label="Sebutkan Dari Hirarki Jabatan yg Paling Rendah Ke Hirarki Jabatan Yg Paling Tinggi"
              multiple
              disable={!isEdit}
              returnObject={
                typeof getValues(`institutional_plan.hirarki_position`) === "object"
              }
            />
          </div>
          <div className={"mt-[49px]"}>
            <QuestionBadge text="Berapa Lama Waktu yang Lembaga Anda Butuhkan Untuk Membuat Rencana Strategis?" />
          </div>
          <RadioBadge
            arrData={optTimeCreatePlanStrategic}
            isEdit={isEdit}
            name={`institutional_plan.plan_strategy_organization_time`}
            className={`mt-[11px] flex gap-[18px]`}
            errors={errors?.institutional_plan?.plan_strategy_organization_time ? errors?.institutional_plan?.plan_strategy_organization_time : false}
          />
        </div>
        {/* button Verification */}
        <RadioVerificationBadge
          className={`mt-[24px] border-[2px] border-[#D0D5DD] rounded-2xl p-4 flex items-center`}
          name={"institutional_plan.status_approval"}
          nameNotes={"institutional_plan.notes_approval"}
          isEditVerification={isEditVerification}
          toogleIsEditVerification={toogleIsEditVerification}
          errors={errors?.institutional_plan?.status_approval ? errors?.institutional_plan?.status_approval : false}
        />
      </CardBody>
    </Card>
  );
};

export default FormInstitutionalPlan;
