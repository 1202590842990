import Input from 'components/atoms/Input';
import React from 'react';

import { Controller, useFormContext } from 'react-hook-form';

const InputFormRadio = ({
  controllerName,
  className,
  label,
  required = false,
  textArea = false,
  usingGrid = false,
  values = [
    {
      label: '1',
      value: 1,
    },
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '4',
      value: 4,
    },
    {
      label: '5',
      value: 5,
    },
  ],
  directionColumn = false,
  disabled = false,
  onChangeValue,
  ...props
}) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={controllerName}
      control={control}
      defaultValue={''}
      render={({
        field: { onChange, value },
        fieldState: { invalid, error },
      }) => {
        return (
          <div className="form-control w-full">
            <label className="label font-semibold text-[14px] ">
              <span className={`label-text`}>{label}</span>
              {required && <span className="text-[#F04438]">*</span>}
            </label>
            {usingGrid ? (
              <div className={`grid grid-cols-3 gap-4 mt-2`}>
                {values
                  ? values.map((data, index) => (
                      <div
                        onChange={() => onChange}
                        className={`${
                          directionColumn ? 'mt-2' : ''
                        } flex items-center flex-1 ${
                          value === data.value
                            ? 'border-[#01A24A] bg-[#EEFFF4]'
                            : ''
                        } bg-white border py-2 px-4 rounded-lg gap-4`}
                      >
                        <Input
                          type="radio"
                          id="2"
                          name={controllerName}
                          {...props}
                          onChange={onChange}
                          value={data.value}
                        />
                        {data.label}
                      </div>
                    ))
                  : 'no values'}
              </div>
            ) : (
              <div
                className={`${
                  directionColumn ? 'flex-column' : 'flex'
                } flex-wrap gap-4 mt-2`}
              >
                {values
                  ? values.map((data, index) => (
                      <div
                        onChange={() => onChange}
                        className={`${
                          directionColumn ? 'mt-2' : ''
                        } flex items-center flex-1 ${
                          !disabled && value === data.value
                            ? 'border-[#01A24A] bg-[#ECFDF3]'
                            : 'border-[#D0D5DD] bg-[#FAFAFA]'
                        } bg-white border py-2 px-4 rounded-lg gap-4`}
                      >
                        <input
                          type="radio"
                          name={controllerName}
                          {...props}
                          disabled={disabled}
                          className={
                            !disabled
                              ? value === data.value &&
                                `bg-[#00BD52] border-none`
                              : value === data.value && `bg-[#D0D5DD]`
                          }
                          onChange={(e) => {
                            if (onChangeValue) return onChangeValue(e);

                            if (['true', 'false'].includes(e.target.value)) {
                              return setValue(controllerName, e.target.value === 'true');
                            }

                            return onChange(e);
                          }}
                          value={data.value}
                          checked={value === data.value}
                        />
                        {data.label}
                      </div>
                    ))
                  : 'no values'}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default InputFormRadio;
